import React from 'react';
import PropTypes from 'prop-types';
import { useFlags as LDUseFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

import { FLAGS } from './flags';

const flagValues = Object.values(FLAGS);

export const isDevMode = false;

export const getLDFlag = (ldFlags, flag) => {
    if (isDevMode) {
        return true;
    }

    if (!flagValues.includes(flag)) {
        throw new Error('Invalid flag! Flags must be defined in flags.js!');
    }

    if (process.env.SERVER) {
        return (global.SSR_ALL_LD_FLAGS && global.SSR_ALL_LD_FLAGS[flag]) || false;
    }

    // If we don't have flags just return false. Don't want to expose features on accident when internet goes down.
    return (ldFlags && ldFlags[flag]) || false;
};

export const withFlags = () => {
    return (WrappedComponent) => {
        class ComponentWithFlags extends React.Component {
            static propTypes = {
                flags: PropTypes.object,
            };

            getFlag = (flag) => {
                return getLDFlag(this.props.flags, flag);
            };

            render() {
                return <WrappedComponent {...this.props} getFlag={this.getFlag} />;
            }
        }
        if (isDevMode) {
            return ComponentWithFlags;
        }
        return withLDConsumer()(ComponentWithFlags);
    };
};

export const useFlags = (flag) => {
    if (isDevMode) {
        return true;
    }
    const flags = LDUseFlags();

    return getLDFlag(flags, flag);
};
