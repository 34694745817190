import { USE_PROCURATED } from '@og-pro/shared-config/procurated';

import { CACHE_BLOCK_SIZE } from './constants';
import { updateVendorCount } from '../../actions/vendorList';

/**
 * Reaches out to retrieve Procurated ratings for a set of vendors
 * @param {*} params Request parameters
 * @param {*} response The result of the get vendor list call
 * @param {*} loadProcuratedRatingsClient Function that reaches out to the Procurated services
 * @param {*} dispatch Redux dispatch function
 * @param {*} isSubscriberView Whether the table is in subscriber view
 * @returns List of vendors with ratings
 */
const getVendorRatings = (
    params,
    response,
    loadProcuratedRatingsClient,
    dispatch,
    isSubscriberView
) => {
    if (USE_PROCURATED && response.results && response.results.length > 0) {
        const suppliers = response.results.map((supplier) => ({
            ...supplier,
            expanded: isSubscriberView,
        }));

        const supplierIds = [...new Set(suppliers.map((supplier) => supplier.id))];
        return dispatch(
            loadProcuratedRatingsClient({
                supplierIds,
            })
        )
            .then((ratingsResponse) => {
                const suppliersWithRatings = suppliers.map((supplier) => {
                    const vendorRating = ratingsResponse.find((rating) => {
                        return parseInt(rating.partnerId, 10) === supplier.id;
                    });

                    return {
                        ...supplier,
                        rating: vendorRating && vendorRating.rating,
                    };
                });
                const res = { rowData: suppliersWithRatings, rowCount: response.count };
                params.success(res);
                return res;
            })
            .catch(() => {
                const res = { rowData: suppliers, rowCount: response.count };
                // We don't want to fail vendor list if ratings are unavailable
                params.success(res);
                return res;
            });
    }
};

export const retrieveVendors = (
    serverFunction,
    govCode,
    searchObject,
    loadProcuratedRatingsClient,
    dispatch,
    isPublic,
    isSubscriberView
) => {
    return {
        getRows: (params) =>
            dispatch(
                serverFunction(
                    govCode,
                    {
                        searchObject,
                        size: CACHE_BLOCK_SIZE,
                        from: params.request.startRow,
                        sortModel: params.request.sortModel,
                    },
                    isPublic,
                    isSubscriberView
                )
            )
                .then((response) => {
                    dispatch(updateVendorCount(response.count));
                    return response;
                })
                .then((response) => {
                    // Procurated doesn't currently allow ratings to be accessed by the public
                    if (isPublic) {
                        return {
                            rowData: response.results,
                            rowCount: response.count,
                        };
                    }

                    return getVendorRatings(
                        params,
                        response,
                        loadProcuratedRatingsClient,
                        dispatch,
                        isSubscriberView
                    );
                })
                .then(({ rowData, rowCount }) => {
                    return params.success({
                        rowData: rowData.map((result) => ({
                            ...result,
                            expanded: isSubscriberView,
                            isPublic,
                        })),
                        rowCount,
                    });
                })
                .catch(() => {
                    params.fail();
                }),
    };
};
