import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { Button, Typography } from '@og-pro/ui';
import { useSelector } from 'react-redux';

import { ActionButtons } from './ActionButtons';
import { ActionError } from './ActionError';
import { StatusBar } from './StatusBar';

import { getRequestsPath } from '../selectors';

export const RequisitionsApprovalHeader = ({
    disabled,
    requisition,
    requisition: { identifier, descriptionOfRequest },
}) => {
    const styles = require('./index.scss');
    const requestPath = useSelector(getRequestsPath);
    return (
        <div className={styles.headerContainer}>
            <Button
                color="secondary"
                component={Link}
                qaTag="requisitionsApproval-backToRequests"
                size="small"
                startIcon={<ArrowBackIcon />}
                sx={{ mb: 1 }}
                to={requestPath}
                variant="text"
            >
                Back to All Requests
            </Button>
            <div className={styles.headerContent}>
                <div className={styles.headerDetails}>
                    {descriptionOfRequest ? (
                        <div className={styles.headerTitle}>
                            <Typography maxWidth="100%" noWrap variant="h2">
                                {descriptionOfRequest}
                            </Typography>
                            <Typography variant="bodyDefault">{identifier}</Typography>
                        </div>
                    ) : (
                        <h1>{identifier}</h1>
                    )}
                    <StatusBar requisition={requisition} />
                </div>
                <div>
                    <ActionButtons disabled={disabled} />
                </div>
            </div>
            <ActionError />
        </div>
    );
};

RequisitionsApprovalHeader.propTypes = {
    disabled: PropTypes.bool,
    requisition: PropTypes.shape({
        identifier: PropTypes.string.isRequired,
        descriptionOfRequest: PropTypes.string,
    }).isRequired,
};
