import React from 'react';
import {
    Button,
    Box,
    buildQaTag,
    componentNames,
    DropdownMenu,
    ListItem,
    ListItemButton,
    ListItemText,
} from '@og-pro/ui';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';

export const ActionButtons = ({ buttons, disabled }) => {
    const dispatch = useDispatch();

    return buttons.map(({ header, icon: Icon, onClick, options, qaTag, text, ...props }, index) => {
        const sharedProps = {
            disabled,
            key: index,
            ...props,
        };
        if (!options) {
            return (
                <Button
                    {...sharedProps}
                    onClick={() => onClick(dispatch)}
                    qaTag={qaTag}
                    startIcon={Icon && <Icon />}
                >
                    {text}
                </Button>
            );
        }

        return (
            <DropdownMenu
                {...sharedProps}
                color="secondary"
                data-qa={buildQaTag(qaTag, componentNames.BUTTON)}
                endIcon={<ArrowDropDownIcon />}
                id={qaTag}
                label={text}
                variant="outlined"
            >
                <Box sx={{ maxWidth: '250px' }}>
                    {header && (
                        <ListItem disablePadding sx={{ px: 1, py: 0.5 }}>
                            <ListItemText secondary={header} />
                        </ListItem>
                    )}
                    {options.map(
                        (
                            { onClick: optionOnClick, text: optionText, ...optionProps },
                            optIndex
                        ) => (
                            <ListItem disablePadding key={optIndex}>
                                <ListItemButton
                                    disabled={disabled}
                                    onClick={() => {
                                        optionOnClick(dispatch);
                                    }}
                                    qaTag={`${qaTag}Option${optIndex}`}
                                    sx={{ px: 1, py: 0.5 }}
                                    {...optionProps}
                                >
                                    <ListItemText primary={optionText} />
                                </ListItemButton>
                            </ListItem>
                        )
                    )}
                </Box>
            </DropdownMenu>
        );
    });
};

ActionButtons.propTypes = {
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string,
            icon: PropTypes.elementType,
            options: PropTypes.arrayOf(
                PropTypes.shape({
                    onClick: PropTypes.func.isRequired,
                    text: PropTypes.string.isRequired,
                })
            ),
            qaTag: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        })
    ).isRequired,
    disabled: PropTypes.bool,
};
