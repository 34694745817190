import { Delete as DeleteIcon, Menu as MenuIcon } from '@mui/icons-material';
import { Box, Button, ReduxFormTextField } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { FormGroup } from '..';
import { qaTagName } from '../../../../constants';
import { useRequestTypeActions } from '../../../../RequestTypeActionsContext';

export const SectionTitleFormGroup = ({
    disabled,
    sectionInstructionsField,
    sectionNameField,
    handleDeleteSectionClick = undefined,
}) => {
    const { showFormValidation } = useRequestTypeActions();

    return (
        <FormGroup Icon={MenuIcon} title="Title">
            <Field
                component={ReduxFormTextField}
                disabled={disabled}
                fullWidth
                label="Section Name*"
                name={sectionNameField}
                qaTag={`${qaTagName}-${sectionNameField}`}
                showValidation={showFormValidation}
            />
            <Field
                component={ReduxFormTextField}
                description="Optional secondary information intended to guide users on how to answer the question."
                disabled={disabled}
                fullWidth
                label="Instruction Text"
                name={sectionInstructionsField}
                qaTag={`${qaTagName}-${sectionInstructionsField}`}
            />
            {handleDeleteSectionClick && (
                <Box>
                    <Button
                        color="error"
                        onClick={handleDeleteSectionClick}
                        qaTag={`${qaTagName}-delete-section`}
                        startIcon={<DeleteIcon />}
                        variant="text"
                    >
                        Delete Section
                    </Button>
                </Box>
            )}
        </FormGroup>
    );
};

SectionTitleFormGroup.propTypes = {
    disabled: PropTypes.bool,
    sectionInstructionsField: PropTypes.string.isRequired,
    sectionNameField: PropTypes.string.isRequired,
    handleDeleteSectionClick: PropTypes.func,
};
