import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionPanelContainer } from '../ActionPanelContainer';
import { ActionPanelItem } from '../ActionPanelItem';
import { RejectionNoteModal } from '../RejectionNoteModal';
import { ReviewerDisplay } from './ReviewerDisplay';
import { getReviewerActionButtons } from '../selectors';
import { hideRejectionNoteModal } from '../../../../../../actions/requisitionsApprovals';

export const ReviewerActionPanel = ({ currentStep, disabled, reviewSequenceSteps }) => {
    const dispatch = useDispatch();
    const actionButtons = useSelector(getReviewerActionButtons);
    const buttons = actionButtons.filter(({ text }) => text !== 'Remind'); // Remove remind button

    useEffect(() => () => dispatch(hideRejectionNoteModal()), []);

    const { id: currentStepId, name, requireRejectionNote } = currentStep;
    const stepNumber = reviewSequenceSteps.findIndex((step) => step.id === currentStepId) + 1;

    return (
        <ActionPanelContainer buttons={buttons} disabled={disabled}>
            <ActionPanelItem label={`Step ${stepNumber} of ${reviewSequenceSteps.length}`}>
                {name}
            </ActionPanelItem>
            <ActionPanelItem label="Waiting For">
                <ReviewerDisplay currentStep={currentStep} />
            </ActionPanelItem>
            <RejectionNoteModal requireRejectionNote={requireRejectionNote} />
        </ActionPanelContainer>
    );
};

ReviewerActionPanel.propTypes = {
    currentStep: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        requireRejectionNote: PropTypes.bool,
    }).isRequired,
    disabled: PropTypes.bool,
    reviewSequenceSteps: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
        })
    ).isRequired,
};
