import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';

import { withFlags, FLAGS } from '@og-pro/launch-darkly/client';

import { filterRecords, handleFilterChange } from '../helper';

import { RecordTable } from '..';
import { recentlyClosedTableColumnDef, recentlyClosedTableEnhancedColumnDef } from '../constants';
import { getActiveRetentionCodes } from '../../../selectors';
import { getPoliciesAssignedJS, getRecentlyClosedJS } from '../../selectors';
import { LoadingSpinner } from '../../../../../components';
import { assignPolicies } from '../../../../../actions/projects';

const mapStateToProps = (state) => {
    return {
        assigningPolicies: state.projects.get('assigningPolicies'),
        policiesAssigned: getPoliciesAssignedJS(state),
        loadingRecentlyClosed: state.projects.get('loadingRecentlyClosed'),
        policies: getActiveRetentionCodes(state),
        recentlyClosed: getRecentlyClosedJS(state),
    };
};

const ConnectedRecordTableRecentlyClosed = ({
    assigningPolicies,
    getFlag,
    policiesAssigned,
    loadingRecentlyClosed,
    policies,
    recentlyClosed,
}) => {
    const dispatch = useDispatch();
    const [changedRows, setChangedRows] = useState([]);
    const [errors, setErrors] = useState([]);

    // recordTypeFilter state is used to keep track of which record type we are filtering by (contracts, documents, intakes)
    // filter state is used to determine the currently selected filter in the dropdown UI
    const [recordTypeFilter, setRecordTypeFilter] = useState();
    const [filter, setFilter] = useState();

    const [selectedRows, setSelectedRows] = useState([]);
    const policyWarning =
        'Please apply a retention policy to each of these contracts and projects whose close date or end date has passed.';
    const showEnhancements = getFlag(FLAGS.RECORDS_RETENTION_ENHANCEMENTS);

    useEffect(() => {
        setChangedRows([]);
    }, [recentlyClosed]);

    useEffect(() => {
        setErrors(policiesAssigned.filter((assignment) => assignment.error));
    }, [policiesAssigned]);

    const handleApplyPolicies = () => {
        dispatch(assignPolicies(changedRows));
    };

    const handleCellValueChanged = (event) => {
        let previouslyUpdatedRows = changedRows;
        const newestUpdatedRow = event.data;

        const updatedRowIds = changedRows.map((changedRow) => changedRow.id);
        if (updatedRowIds.includes(newestUpdatedRow.id)) {
            previouslyUpdatedRows = changedRows.filter(
                (previouslyUpdatedRow) => previouslyUpdatedRow.id !== newestUpdatedRow.id
            );
        }

        setChangedRows([...previouslyUpdatedRows, newestUpdatedRow]);
    };

    const handleSelectPolicy = (policyId) => {
        const rowsWithSelectedPolicy = selectedRows.map((row) => ({
            ...row,
            retentionPolicy: {
                ...row.retentionPolicy,
                selectedPolicy: policyId,
            },
        }));
        setSelectedRows([]);
        setChangedRows([...rowsWithSelectedPolicy]);
    };

    // setFilter() is called to change the value of the dropdown in the UI
    // setRecordTypeFilter() is called to change the value of the current record type filter
    const setFilters = (newFilter) => handleFilterChange(newFilter, setFilter, setRecordTypeFilter);

    const rows = recentlyClosed
        .filter((record) => {
            return filterRecords(record, filter, recordTypeFilter);
        })
        .map((record) => {
            const closeDate = record.recordCloseDate || record.endDate;
            const rowError = errors.find((error) => error.id === record.id);
            let retentionPolicy = {
                selectedPolicy: record.retention_code_id,
                options: policies,
                closeDate,
                rowError,
            };
            const updatedIds = changedRows.map((changedRow) => changedRow.id);
            if (updatedIds.includes(record.id)) {
                retentionPolicy = changedRows.find(
                    (changedRow) => changedRow.id === record.id
                ).retentionPolicy;
            }
            const availableRenewals =
                showEnhancements && record.contractRenewals
                    ? record.contractRenewals.reduce(
                          (acc, renewal) => acc + (renewal.selected === false ? 1 : 0),
                          0
                      )
                    : 0;

            return {
                id: record.id,
                title: {
                    title: record.title,
                    projectId: record.id,
                    financialId: record.financialId || record.contractId,
                    governmentId: record.government_id,
                    openRenewals: availableRenewals,
                    recordType: record.recordType,
                },
                department: record.departmentName,
                ...(showEnhancements
                    ? {
                          closedDate: {
                              date: moment(closeDate).format('ll'),
                              rawDate: closeDate,
                              reason: record.closeOutReason || record.status,
                              recordType: record.recordType,
                          },
                          lastUpdated: moment(record.updated_at).format('lll'),
                      }
                    : {
                          lastUpdated: {
                              date: moment(record.updated_at).format('lll'),
                              rawDate: record.updated_at,
                              reason: record.closeOutReason || record.status,
                              recordType: record.recordType,
                          },
                      }),
                contact: record.contactDisplayName,
                retentionPolicy,
                recordType: record.recordType,
            };
        });

    if (loadingRecentlyClosed && rows.length === 0) {
        return <LoadingSpinner />;
    }

    return (
        <RecordTable
            columnDef={
                showEnhancements
                    ? recentlyClosedTableEnhancedColumnDef
                    : recentlyClosedTableColumnDef
            }
            disableApplyPolicies={changedRows.length === 0}
            errors={errors}
            filter={filter}
            handleApplyPolicies={handleApplyPolicies}
            handleCellValueChanged={handleCellValueChanged}
            handleSelectPolicy={handleSelectPolicy}
            loading={assigningPolicies || loadingRecentlyClosed}
            policies={policies}
            policyWarning={policyWarning}
            rows={rows}
            selectedRows={selectedRows}
            setFilter={setFilters}
            setSelectedRows={setSelectedRows}
            showApplyPolicies
            showPolicies={selectedRows.length > 0}
        />
    );
};

ConnectedRecordTableRecentlyClosed.propTypes = {
    assigningPolicies: PropTypes.bool.isRequired,
    getFlag: PropTypes.func.isRequired,
    policiesAssigned: PropTypes.array.isRequired,
    loadingRecentlyClosed: PropTypes.bool.isRequired,
    policies: PropTypes.array.isRequired,
    recentlyClosed: PropTypes.array.isRequired,
};

export const RecordTableRecentlyClosed = compose(
    connect(mapStateToProps),
    withFlags()
)(ConnectedRecordTableRecentlyClosed);
