import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroupItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import { sectionTypeNames } from '@og-pro/shared-config/sections';

import { criteriaInstructionsMap } from '@og-pro/shared-config/criteria';

import { MANUAL_NUMBER_CHAR_LIMIT } from '@og-pro/shared-config/manualNumber';

import { fieldNames } from '../constants';
import { Button } from '../../../Button';
import { CDSButton } from '../../../SDv2/CDSButtons/CDSButton';
import { CDSButtonGroup } from '../../../SDv2/CDSButtons/CDSButtonGroup';
import { Checkbox } from '../../../Checkbox/Checkbox';
import { DragIcon } from '../../../DragIcon';
import { HelpToolTip } from '../../../HelpToolTip/HelpToolTip';
import { InputText } from '../../../InputText';
import { Label } from '../../../InputText/Label';
import { RichTextInput } from '../../../RichTextInput';
import { criteriaNeedsReviewShowModal } from '../../../../actions/templatesAdmin';
import { CommentIcon } from '../../../../containers/GovApp';

import { limitTextLength } from '../../../../Forms/normalizers';

import { CriteriaItemV2 } from '../../../SDv2/Forms/CriteriaForm/CriteriaItem';
import { OGThemeContext } from '../../../../containers/GovApp/ogThemeProvider';
import { CriteriaItemTextBuilder } from './Builder';
import { CriteriaItemTextArea } from './TextArea';

const { DESCRIPTION, IS_UPLOAD, IS_UPLOAD_REQUIRED, MANUAL_NUMBER, RAW_DESCRIPTION, TITLE } =
    fieldNames;

const { SCOPE } = sectionTypeNames;

const mapDispatchToProps = {
    showCriteriaNeedsReviewModal: criteriaNeedsReviewShowModal,
};

// @connect
class ConnectedCriteriaItem extends Component {
    // all object fields are an instance of the nested criteria fields provided by redux-form
    static propTypes = {
        arrayName: PropTypes.string.isRequired,
        criteria: PropTypes.shape({
            id: PropTypes.number,
            instructions: PropTypes.string,
            instructionType: PropTypes.string,
            isTitleLocked: PropTypes.bool,
            isUpload: PropTypes.bool,
            orderById: PropTypes.number,
            manualNumber: PropTypes.string,
            needsReview: PropTypes.bool,
            section_type: PropTypes.string.isRequired,
            sharedId: PropTypes.string.isRequired,
            title: PropTypes.string,
        }).isRequired,
        disabled: PropTypes.bool,
        dragHandleProps: PropTypes.object.isRequired,
        elRef: PropTypes.object,

        forceRenderDragIcon: PropTypes.bool,
        handleInsert: PropTypes.func.isRequired,
        hasUpload: PropTypes.bool,
        index: PropTypes.number.isRequired,
        isLastItem: PropTypes.bool,
        isDragging: PropTypes.bool,
        isCriteriaBuilder: PropTypes.bool,
        isSharedSectionForm: PropTypes.bool,
        isTextArea: PropTypes.bool,
        moveFieldDown: PropTypes.func,
        moveFieldUp: PropTypes.func,
        projectSection: PropTypes.object.isRequired,
        questionLogicIcon: PropTypes.node,
        remove: PropTypes.func.isRequired,
        showComment: PropTypes.bool,
        showCriteriaNeedsReviewModal: PropTypes.func.isRequired,
        showValidation: PropTypes.bool,
        sortUsingManualNumbering: PropTypes.func,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        useManualNumbering: PropTypes.bool,
        useRawDescription: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
        hasUpload: false,
        isCriteriaBuilder: false,
        isTextArea: false,
        showComment: false,
        showValidation: false,
        tagOptions: undefined,
        templateVariableOptions: undefined,
        useRawDescription: false,
    };

    static contextType = OGThemeContext;

    get styles() {
        return require('../shared.scss');
    }

    handleClick = () => {
        const { index, remove } = this.props;

        remove(index);
    };

    insertHandler = () => {
        const {
            criteria: { orderById },
            handleInsert,
        } = this.props;

        handleInsert(orderById);
    };

    showCriteriaNeedsReviewModal = () => {
        const { arrayName, criteria, showCriteriaNeedsReviewModal } = this.props;

        showCriteriaNeedsReviewModal({
            arrayName,
            criteria,
        });
    };

    normalizeManualNumber = limitTextLength(MANUAL_NUMBER_CHAR_LIMIT);

    renderDragIcon = () => {
        const {
            criteria: { needsReview },
            disabled,
            dragHandleProps,
            isCriteriaBuilder,
            isTextArea,
            useManualNumbering,
            forceRenderDragIcon,
        } = this.props;

        // When criteria needs review we could be dealing with a subset of items, so reordering
        // should not be allowed.
        // `textArea` items also should not be reordered since they are single items.
        // `react-beautiful-dnd` still requires a draggable area though, so we create this
        // unclickable div.
        // 2023-22-11: added a new prop forceRenderDragIcon that shows the drag icon despite the manual numbering
        // this is the address the shared sections where manual numbering is not activated per se but more of an
        // optional thing. So you might have no manual numbers and want to drag things around
        if (
            (needsReview && !isCriteriaBuilder) ||
            isTextArea ||
            (useManualNumbering && !forceRenderDragIcon)
        ) {
            return <div className={this.styles.hiddenDragIcon} {...dragHandleProps} />;
        }

        return (
            <DragIcon
                className={this.styles.dragIcon}
                disabled={disabled}
                dragHandleProps={dragHandleProps}
            />
        );
    };

    renderInstructions() {
        const { criteria } = this.props;

        if (!criteria.instructions) return null;

        return (
            <p className={this.styles.instructions}>
                <strong className="text-primary">Instructions:</strong>&nbsp;
                {criteria.instructions}
            </p>
        );
    }

    renderUpload() {
        const { arrayName, criteria, hasUpload } = this.props;

        if (!hasUpload) return null;

        return (
            <div className={this.styles.uploadContainer}>
                <Field
                    component={Checkbox}
                    formGroupClass={this.styles.isUploadCheckbox}
                    inline
                    name={`${arrayName}.${IS_UPLOAD}`}
                    text="Upload"
                />
                <HelpToolTip
                    className={this.styles.uploadTooltip}
                    text="Use to select items that the vendor should submit an upload as part of their response"
                />
                {criteria.isUpload && (
                    <Field
                        component={Checkbox}
                        formGroupClass={this.styles.isUploadRequiredCheckbox}
                        inline
                        name={`${arrayName}.${IS_UPLOAD_REQUIRED}`}
                        text="Required"
                    />
                )}
            </div>
        );
    }

    shouldRenderCommentIcon = () => {
        return this.props.showComment;
    };

    renderCommentIcon = () => {
        const { isOGThemeEnabledForComponents } = this.context;
        const { criteria, projectSection } = this.props;

        if (!this.shouldRenderCommentIcon()) {
            return null;
        }

        const hasId = !!criteria.id;

        if (isOGThemeEnabledForComponents && !hasId) {
            // otherwise we get an empty block in the ContentBlock.Actions
            return null;
        }

        return (
            <CommentIcon
                criterionId={hasId ? criteria.id : undefined}
                iconLeft
                openGovStyleIconOnly={isOGThemeEnabledForComponents}
                projectSection={projectSection}
                qaTag="criteriaItem-comments"
                show={hasId}
                useOpenGovStyle={isOGThemeEnabledForComponents}
            /> // Criteria must be saved to show
        );
    };

    renderDeleteButton = () => {
        const { isOGThemeEnabledForComponents } = this.context;
        const {
            criteria: { isTitleLocked },
            disabled,
            useManualNumbering,
        } = this.props;

        return (
            <Button
                aria-label="Remove Button"
                bsStyle="link"
                className={classnames(
                    this.styles.removeBtn,
                    useManualNumbering && this.styles.addMargin
                )}
                disabled={isTitleLocked || disabled}
                onClick={this.handleClick}
                qaTag="criteriaItem-remove"
                tooltip={isTitleLocked ? 'This item is required and cannot be removed' : undefined}
                tooltipPlacement="right"
            >
                <i
                    className={classnames('fa fa-2x', {
                        'fa-times': !isOGThemeEnabledForComponents,
                        'fa-trash': isOGThemeEnabledForComponents,
                    })}
                />
            </Button>
        );
    };

    renderInsertButton() {
        const { isOGThemeEnabledForComponents } = this.context;
        const { disabled, isTextArea } = this.props;

        if (isTextArea) return null;

        if (isOGThemeEnabledForComponents) {
            return (
                <CDSButtonGroup>
                    <CDSButton
                        aria-label="Insert Button"
                        disabled={disabled}
                        onClick={this.insertHandler}
                        qaTag="criteriaItem-insert"
                        size="small"
                        variant="secondary-alt"
                    >
                        <i className="fa fa-plus" /> Add a List Item
                    </CDSButton>
                </CDSButtonGroup>
            );
        }

        return (
            <Button
                aria-label="Insert Button"
                bsStyle="link"
                className={this.styles.addBtn}
                disabled={disabled}
                onClick={this.insertHandler}
                qaTag="criteriaItem-insert"
                tooltip="Insert after"
                tooltipPlacement="right"
            >
                <i className="fa fa-plus fa-2x" />
            </Button>
        );
    }

    renderQuestionLogicButton() {
        const { questionLogicIcon } = this.props;

        return <div className={this.styles.questionLogicIcon}>{questionLogicIcon}</div>;
    }

    renderReviewInstructionsButton(isActive) {
        const { disabled } = this.props;
        return (
            <Button
                active={isActive}
                aria-label="Add review instructions"
                bsStyle={isActive ? undefined : 'link'}
                className={this.styles.criteriaBuilderIcon}
                disabled={disabled}
                onClick={this.showCriteriaNeedsReviewModal}
                qaTag="criteriaItem-addReviewInstructions"
                tooltip="Add review instructions to item"
                tooltipPlacement="left"
            >
                <i className="fa fa-lg fa-code text-primary" />
            </Button>
        );
    }

    renderNeedsReviewButton = (isActive) => {
        const { questionLogicIcon } = this.props;

        return (
            <>
                {!!questionLogicIcon && this.renderQuestionLogicButton()}
                {this.renderReviewInstructionsButton(isActive)}
            </>
        );
    };

    renderCriteriaItemBuilder() {
        const {
            criteria: { instructions, instructionType, manualNumber, title: criteriaTitle },
            disabled,
            elRef,
            isLastItem,
            index,
            isTextArea,
            moveFieldDown,
            moveFieldUp,
            projectSection,
            projectSection: { title: sectionTitle },
            useManualNumbering,
        } = this.props;
        const { isOGThemeEnabledForComponents } = this.context;

        const criteriaInstructionsData = criteriaInstructionsMap[instructionType];
        const manualNumbering = useManualNumbering && manualNumber !== null ? manualNumber : '';
        const title = isTextArea ? sectionTitle : criteriaTitle;

        if (isOGThemeEnabledForComponents) {
            return (
                <>
                    <CriteriaItemTextBuilder
                        criteriaInstructionsData={criteriaInstructionsData}
                        disabled={disabled || projectSection.isTemplate}
                        index={index}
                        instructions={instructions}
                        isDragging={this.props.isDragging}
                        isLastItem={isLastItem}
                        manualNumbering={manualNumbering}
                        moveFieldDown={moveFieldDown}
                        moveFieldUp={moveFieldUp}
                        projectSection={projectSection}
                        renderCommentIcon={this.renderCommentIcon}
                        renderDeleteButton={this.renderDeleteButton}
                        renderDragIcon={this.renderDragIcon}
                        renderNeedsReviewButton={this.renderNeedsReviewButton}
                        shouldRenderCommentIcon={this.shouldRenderCommentIcon}
                        showCriteriaNeedsReviewModal={this.showCriteriaNeedsReviewModal}
                        title={title}
                    />
                    {this.renderInsertButton()}
                </>
            );
        }

        return (
            <div className={this.styles.criteria}>
                <div className="row">
                    <div className="col-xs-10 col-sm-11" ref={elRef || null}>
                        <ListGroupItem onClick={this.showCriteriaNeedsReviewModal}>
                            <Label label={`${manualNumbering} ${title}`} />
                            <p className="text-muted">
                                <em>Item will be reviewed by the user when completing the form.</em>
                            </p>
                            <Label
                                className="text-info"
                                label={
                                    <>
                                        <i
                                            className={`fa fa-fw fa-${criteriaInstructionsData.icon}`}
                                        />
                                        &nbsp;
                                        {criteriaInstructionsData.name}
                                    </>
                                }
                            />
                            <div>{instructions}</div>
                        </ListGroupItem>
                    </div>
                    <div className="col-xs-2 col-sm-1">
                        <div className={this.styles.controlButtons}>
                            <div className={this.styles.editingControls}>
                                {!isTextArea && this.renderDeleteButton()}
                                {this.renderDragIcon()}
                                {!isTextArea && this.renderInsertButton()}
                            </div>
                            {this.renderNeedsReviewButton(true)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderTextAreaItemInput = () => {
        const {
            arrayName,
            disabled,
            showValidation,
            tagOptions,
            templateVariableOptions,
            useRawDescription,
        } = this.props;
        const { isOGThemeEnabledForComponents } = this.context;

        return (
            <Field
                borderless={isOGThemeEnabledForComponents}
                component={RichTextInput}
                disabled={disabled}
                minRows={10}
                name={`${arrayName}.${useRawDescription ? RAW_DESCRIPTION : DESCRIPTION}`}
                overrideFeedback={isOGThemeEnabledForComponents}
                placeholder="Enter Text"
                showValidation={showValidation}
                tagOptions={tagOptions}
                templateVariableOptions={templateVariableOptions}
                toolbarPlacement="bottom"
                useOpenGovStyle
                useSharedTextareaToolbar={isOGThemeEnabledForComponents}
            />
        );
    };

    renderTextAreaItem() {
        const { disabled, isCriteriaBuilder, projectSection, showValidation } = this.props;
        const { isOGThemeEnabledForComponents } = this.context;

        if (isOGThemeEnabledForComponents) {
            return (
                <CriteriaItemTextArea
                    disabled={disabled}
                    isCriteriaBuilder={isCriteriaBuilder}
                    projectSection={projectSection}
                    renderCommentIcon={this.renderCommentIcon}
                    renderDragIcon={this.renderDragIcon}
                    renderNeedsReviewButton={this.renderNeedsReviewButton}
                    renderTextAreaItemInput={this.renderTextAreaItemInput}
                    shouldRenderCommentIcon={this.shouldRenderCommentIcon}
                    showValidation={showValidation}
                />
            );
        }

        return (
            <div className={`row ${this.styles.criteria}`}>
                <div className="col-xs-10 col-sm-11">{this.renderTextAreaItemInput()}</div>
                <div className="col-xs-2 col-sm-1">
                    <div className={classnames(this.styles.controlButtons, this.styles.topControl)}>
                        {this.renderCommentIcon()}
                        {this.renderDragIcon()}
                        {isCriteriaBuilder && this.renderNeedsReviewButton()}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { isOGThemeEnabledForComponents } = this.context;
        const {
            arrayName,
            criteria,
            disabled,
            elRef,
            index,
            isCriteriaBuilder,
            isLastItem,
            isSharedSectionForm,
            isTextArea,
            moveFieldDown,
            moveFieldUp,
            projectSection,
            showValidation,
            sortUsingManualNumbering,
            tagOptions,
            templateVariableOptions,
            useManualNumbering,
            useRawDescription,
        } = this.props;

        if (isCriteriaBuilder && criteria.needsReview) {
            return this.renderCriteriaItemBuilder();
        }

        if (isTextArea) {
            return this.renderTextAreaItem();
        }

        const titleFieldProps = {
            'aria-label': 'Title',
            autoFocus: !criteria[TITLE],
            component: InputText,
            disabled: disabled || criteria.isTitleLocked,
            formGroupClassName: this.styles.titleItem,
            hasFeedback: false,
            name: `${arrayName}.${TITLE}`,
            placeholder: 'Enter Title',
            qaTag: `criteriaItem-${index}-title`,
            showValidation,
            type: 'text',
        };

        const descriptionFieldProps = {
            'aria-label': 'Description',
            borderless: true,
            component: RichTextInput,
            disabled,
            name: `${arrayName}.${useRawDescription ? RAW_DESCRIPTION : DESCRIPTION}`,
            placeholder: 'Enter Text',
            qaTag: `criteriaItem-${index}-description`,
            showValidation,
            tagOptions,
            templateVariableOptions,
            toolbarPlacement: 'bottom',
        };

        if (isOGThemeEnabledForComponents) {
            const showSectionAsDisabled =
                projectSection.isTemplate && isCriteriaBuilder && !isSharedSectionForm;

            return (
                <div ref={elRef}>
                    {/* TODO: instructions will probably need styled  */}
                    {this.renderInstructions()}
                    <CriteriaItemV2
                        actions={{
                            comments: this.renderCommentIcon(),
                            delete: showSectionAsDisabled ? null : this.renderDeleteButton(),
                            drag: this.renderDragIcon(),
                            insert: showSectionAsDisabled ? null : this.renderInsertButton(),
                            ...(isCriteriaBuilder &&
                                criteria.section_type !== SCOPE && {
                                    addReviewInstructions: projectSection.isTemplate
                                        ? null
                                        : this.renderReviewInstructionsButton(),
                                    questionLogic: this.renderQuestionLogicButton(),
                                }),
                            moveDown: !isLastItem && !!moveFieldDown && (
                                <Button bsStyle="link" onClick={() => moveFieldDown(index)}>
                                    <i aria-hidden="true" className="fa fa-arrow-down" />
                                </Button>
                            ),
                            moveUp: index !== 0 && !!moveFieldUp && (
                                <Button bsStyle="link" onClick={() => moveFieldUp(index)}>
                                    <i aria-hidden="true" className="fa fa-arrow-up" />
                                </Button>
                            ),
                        }}
                        description={
                            <Field
                                {...descriptionFieldProps}
                                overrideFeedback
                                showValidation={false}
                                useSharedTextareaToolbar
                            />
                        }
                        disabled={showSectionAsDisabled}
                        errors={{
                            key: arrayName,

                            fields: [
                                TITLE,
                                useRawDescription ? RAW_DESCRIPTION : DESCRIPTION,
                                ...(useManualNumbering ? [MANUAL_NUMBER] : []),
                            ],
                        }}
                        hideDragIcon={showSectionAsDisabled || (isLastItem && index === 0)}
                        isDragging={this.props.isDragging}
                        manualNumber={
                            <Field
                                aria-label="Manual Number"
                                component={InputText}
                                disabled={disabled}
                                hasFeedback={false}
                                name={`${arrayName}.${MANUAL_NUMBER}`}
                                normalize={this.normalizeManualNumber}
                                onBlur={() => sortUsingManualNumbering(criteria.sharedId, index)}
                                placeholder="Enter Number"
                                qaTag={`criteriaItem-${index}-manualNumber`}
                                showValidation={false}
                                type="text"
                                useOpenGovStyle
                            />
                        }
                        showValidation={showValidation}
                        title={
                            <Field
                                {...titleFieldProps}
                                minRows={1}
                                showValidation={showValidation}
                                type="textarea"
                                useOpenGovStyle
                            />
                        }
                        useManualNumbering={useManualNumbering}
                    />
                    {/* TODO: upload will probably need styled  */}
                    {this.renderUpload()}
                </div>
            );
        }

        return (
            <div className={this.styles.criteria}>
                <div className="row">
                    <div className="col-xs-10 col-sm-11">
                        {this.renderInstructions()}
                        <div className="row">
                            {useManualNumbering && (
                                <div className="col-md-3" ref={elRef}>
                                    <Field
                                        aria-label="Manual Number"
                                        component={InputText}
                                        disabled={disabled}
                                        hasFeedback={false}
                                        name={`${arrayName}.${MANUAL_NUMBER}`}
                                        normalize={this.normalizeManualNumber}
                                        onBlur={() =>
                                            sortUsingManualNumbering(criteria.sharedId, index)
                                        }
                                        placeholder="Enter Number"
                                        qaTag={`criteriaItem-${index}-manualNumber`}
                                        showValidation={showValidation}
                                        type="text"
                                    />
                                </div>
                            )}
                            <div className={useManualNumbering ? 'col-md-9' : 'col-xs-12'}>
                                <Field {...titleFieldProps} />
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-2 col-sm-1">
                        <div
                            className={classnames(
                                this.styles.controlButtons,
                                this.styles.topControl
                            )}
                        >
                            {this.renderCommentIcon()}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-10 col-sm-11">
                        <Field
                            aria-label="Description"
                            component={RichTextInput}
                            disabled={disabled}
                            name={`${arrayName}.${
                                useRawDescription ? RAW_DESCRIPTION : DESCRIPTION
                            }`}
                            placeholder="Enter Text"
                            qaTag={`criteriaItem-${index}-description`}
                            showValidation={showValidation}
                            tagOptions={tagOptions}
                            templateVariableOptions={templateVariableOptions}
                            toolbarPlacement="bottom"
                        />
                        {this.renderUpload()}
                    </div>
                    <div className="col-xs-2 col-sm-1">
                        <div className={this.styles.controlButtons}>
                            <div className={this.styles.editingControls}>
                                {this.renderDeleteButton()}
                                {this.renderDragIcon()}
                                {this.renderInsertButton()}
                            </div>
                            {isCriteriaBuilder &&
                                criteria.section_type !== SCOPE &&
                                this.renderNeedsReviewButton()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const CriteriaItem = connect(null, mapDispatchToProps)(ConnectedCriteriaItem);
