import classNames from 'classnames';
import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import React, { useCallback, useEffect, useRef } from 'react';
import { DropdownButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AgGridReact, Box, Button, Typography } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { Download as DownloadIcon } from '@mui/icons-material';

import {
    getInvitedVendorListJS,
    getInvitedVendorOutstandingCount,
    getLoadingInvitedVendorList,
    getShouldLoadInvitedVendorList,
} from '../selectors';
import { VendorManagementHeader } from '../VendorManagementHeader';
import { getPortalUrl } from '../../App/selectors';
import {
    inviteVendorsToPortal,
    loadInvitedVendorList,
    removeVendorSubscription,
} from '../../../../actions/vendorList';
import { LoadingSpinner, Main, MenuItem, ProgressBar, ZeroState } from '../../../../components';

const StatusCellRenderer = (params) => {
    const styles = require('./index.scss');
    const dispatch = useDispatch();
    const { governmentId } = useParams();

    const handleRemoveVendorSubscription = (governmentSubscriptionId) => {
        dispatch(removeVendorSubscription(governmentId, governmentSubscriptionId));
    };

    const handleResendInvite = (email) => {
        dispatch(inviteVendorsToPortal(governmentId, { emails: [email] }, { isReminder: true }));
    };

    const statusEl = (
        <span className={`text-${params.value ? 'success' : 'primary'}`}>
            <i className={`fa fa-fw fa-${params.value ? 'check' : 'envelope-o'}`} />
            &nbsp;
            {params.value ? 'Accepted' : 'Invited'}
        </span>
    );

    const { email, governmentSubscriptionId: subId } = params.data;
    return params.value ? (
        statusEl
    ) : (
        <DropdownButton
            bsStyle="link"
            className={styles.inviteDropdownButton}
            id={`invited-users-options-${email}`}
            title={statusEl}
        >
            <MenuItem
                onClick={() => handleResendInvite(email)}
                qaTag="vendorManagement-resendInvite"
            >
                <i className="fa fa-fw fa-send" /> Resend Invite
            </MenuItem>
            <MenuItem
                onClick={() => handleRemoveVendorSubscription(subId)}
                qaTag="vendorManagement-remove"
            >
                <i className="fa fa-fw fa-ban" /> Remove
            </MenuItem>
        </DropdownButton>
    );
};

export const InvitedVendorList = () => {
    const styles = require('./index.scss');
    const dispatch = useDispatch();

    const gridRef = useRef(null);

    const { governmentId } = useParams();
    const portalUrl = useSelector(getPortalUrl);
    const loading = useSelector(getLoadingInvitedVendorList);
    const invitedVendorList = useSelector(getInvitedVendorListJS);
    const invitedVendorOutstandingCount = useSelector(getInvitedVendorOutstandingCount);
    const shouldLoadInvitedVendorList = useSelector(getShouldLoadInvitedVendorList);

    useEffect(() => {
        if (shouldLoadInvitedVendorList) {
            dispatch(loadInvitedVendorList(governmentId));
        }
    }, [shouldLoadInvitedVendorList]);

    const handleCsvExport = useCallback(() => {
        gridRef.current?.api.exportDataAsCsv();
    }, []);

    const renderProgressBar = () => {
        const invitedVendors = invitedVendorList.length;

        const acceptedVendors = invitedVendors - invitedVendorOutstandingCount;

        return (
            <div className={styles.progressBar}>
                <ProgressBar noBottomMargin>
                    <ProgressBar max={invitedVendors} min={0} now={acceptedVendors} />
                    <ProgressBar
                        bsStyle="warning-dark"
                        max={invitedVendors}
                        min={0}
                        now={invitedVendorOutstandingCount}
                    />
                </ProgressBar>
                <Box display="flex" justifyContent="space-evenly">
                    <Box alignItems="center" display="flex" gap={0.5}>
                        <span className={classNames(styles.dot, styles.accepted)} />
                        <Typography variant="bodySmall">{`Accepted: ${acceptedVendors}`}</Typography>
                    </Box>
                    <Box alignItems="center" display="flex" gap={0.5}>
                        <span className={classNames(styles.dot, styles.pending)} />
                        <Typography variant="bodySmall">{`Pending: ${invitedVendorOutstandingCount}`}</Typography>
                    </Box>
                </Box>
            </div>
        );
    };

    const renderBody = () => {
        if (invitedVendorList.length === 0) {
            return <ZeroState title="No Vendors Have Been Invited Yet" />;
        }

        const defaultColDef = {
            sortable: true,
            resizable: false,
            suppressHeaderMenuButton: true,
        };

        const columnDefs = [
            { field: 'email', headerName: 'Email', flex: 1 },
            {
                field: 'isConverted',
                headerName: 'Status',
                cellRenderer: StatusCellRenderer,
                valueFormatter: (params) => (params.data.isConverted ? 'Accepted' : 'Invited'),
            },
            {
                field: 'initialInviteSent',
                headerName: 'Initial Invite Sent',
                cellRenderer: 'daysFromNowCellRenderer',
                valueFormatter: (params) => moment(params.value).format('lll'),
            },
            {
                field: 'latestInviteSent',
                headerName: 'Latest Invite Sent',
                cellRenderer: 'daysFromNowCellRenderer',
                valueFormatter: (params) => moment(params.value).format('lll'),
            },
        ];

        return (
            <Box className={styles.vendorListContainer} mb={3}>
                <div className="panel-title">
                    Outstanding Vendor Invites ({invitedVendorOutstandingCount || 0})
                </div>
                {renderProgressBar()}
                <Box display="flex" justifyContent="flex-end" mb={1}>
                    <Button color="secondary" onClick={handleCsvExport} variant="outlined">
                        <Box alignItems="center" display="flex" gap={0.5}>
                            <DownloadIcon
                                fontSize={
                                    capitalDesignTokens.foundations.typography.fontSize.bodyDefault
                                }
                            />
                            Export to CSV
                        </Box>
                    </Button>
                </Box>
                <AgGridReact
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    domLayout="autoHeight"
                    innerRef={gridRef}
                    rowData={invitedVendorList}
                    suppressExcelExport
                    suppressRowTransform
                />
            </Box>
        );
    };

    return (
        <>
            <VendorManagementHeader governmentId={governmentId} portalUrl={portalUrl} />
            <Box
                pt={3}
                px={7}
                sx={{
                    backgroundColor: capitalDesignTokens.semanticColors.background.primary,
                    height: '100%',
                }}
            >
                <Main className="row">
                    <div className="col-xs-12 col-md-offset-2 col-md-8">
                        {loading ? <LoadingSpinner text="Loading Vendor Invites" /> : renderBody()}
                    </div>
                </Main>
            </Box>
        </>
    );
};
