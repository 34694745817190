import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { recordDocumentTypesDict } from '@og-pro/shared-config/recordsRetention';

import { CLEANUP_DOCUMENTS } from '../../constants';
import { RecordTable } from '..';
import { recordTableColumnDef } from '../constants';
import { getCleanupDocumentsJS } from '../../selectors';

import { LoadingSpinner } from '../../../../../components';

const { REQUEST_DRAFT, REQUEST_REVIEW } = projectStatusesDict;

const { DOCUMENTS, INTAKES } = recordDocumentTypesDict;

const mapStateToProps = (state) => {
    return {
        cleanupDocuments: getCleanupDocumentsJS(state),
        deleteCleanupDocumentsError: state.projects.get('deleteCleanupDocumentsError'),
        deletingCleanupDocuments: state.projects.get('deletingCleanupDocuments'),
        loadCleanupDocumentsError: state.projects.get('loadCleanupDocumentsError'),
        loadingCleanupDocuments: state.projects.get('loadingCleanupDocuments'),
    };
};

const ConnectedRecordTableDocuments = ({
    cleanupDocuments,
    deleteCleanupDocumentsError,
    deletingCleanupDocuments,
    loadCleanupDocumentsError,
    loadingCleanupDocuments,
    ...props
}) => {
    const location = useLocation();
    const [filter, setFilter] = useState();
    const [selectedRows, setSelectedRows] = useState([]);
    const policyWarning =
        'This is a list of intakes, solicitations, and contract documents. The solicitations are all in draft, review, final or closed status (and have not been publicly bid or attached to a contract). The intakes are either in draft or review statuses (not yet attached to a document). The contract documents are all in draft or review status.';

    let projects;
    switch (filter) {
        case INTAKES:
            projects = cleanupDocuments.filter((document) => document.isIntake);
            break;
        case DOCUMENTS:
            projects = cleanupDocuments.filter((document) => !document.isIntake);
            break;
        default:
            projects = cleanupDocuments;
    }

    const rows = projects.map((project) => {
        let status = project.status;

        if (project.status === REQUEST_DRAFT) {
            status = 'draft';
        }
        if (project.status === REQUEST_REVIEW) {
            status = 'review';
        }

        return {
            id: project.id,
            status: {
                isIntake: project.isIntake,
                status,
            },
            title: {
                title: project.title,
                projectId: project.id,
                financialId: project.financialId,
                governmentId: project.government_id,
            },
            department: project.departmentName,
            contact: project.contactDisplayName,
            lastUpdated: moment(project.updated_at).format('lll'),
        };
    });

    const documentsToCleanUp = rows.length > 0;

    if (loadingCleanupDocuments && !deletingCleanupDocuments) {
        return <LoadingSpinner />;
    }

    return (
        <RecordTable
            columnDef={recordTableColumnDef}
            count={documentsToCleanUp}
            filter={filter}
            loading={deletingCleanupDocuments}
            location={location}
            policyWarning={policyWarning}
            rows={rows}
            selectedRows={selectedRows}
            setFilter={setFilter}
            setSelectedRows={setSelectedRows}
            showDelete
            storeLocation={CLEANUP_DOCUMENTS}
            {...props}
        />
    );
};

ConnectedRecordTableDocuments.propTypes = {
    cleanupDocuments: PropTypes.array.isRequired,
    deleteCleanupDocumentsError: PropTypes.string,
    deletingCleanupDocuments: PropTypes.bool,
    loadCleanupDocumentsError: PropTypes.string,
    loadingCleanupDocuments: PropTypes.bool,
};

export const RecordTableDocuments = connect(mapStateToProps)(ConnectedRecordTableDocuments);
